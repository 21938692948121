import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { DataGrid, GridColDef, GridActionsCellItem} from '@mui/x-data-grid';
import { useState } from 'react';
import { updatePurchaseOrder, deletePurchaseOrderContainer } from "../api/PurchaseOrder/PurchaseOrder.hooks";
import { useMutation } from "react-query";
import DeleteIcon from '@mui/icons-material/Delete';

export default function BookingData(props) {

  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const emailId = props.emailId;
  const containerNumber = props.containerNumber;
  const [customerPO, setCustomerPO] = useState();
  const keysToConvert = ['booking_date', 'cargo_ready_date', 'etd', 'eta_pod',]; // List of keys to convert to datetime
  let rows;

  const mutationDeleteContainer = deletePurchaseOrderContainer([customerPO])
  const handleDeleteContainer = (params) => {
    mutationDeleteContainer.mutate([params.row.customer_po]);
  }
  const columns = [
    { field: 'customer_po', headerName: 'Customer PO Number', width: 200 },
    { field: 'ship_to', headerName: 'ship_to', width: 150 },
    { field: 'item_no', headerName: 'item_no', width: 150 },
    { field: 'item_desc_1', headerName: 'item_desc_1', width: 150 },
    { field: 'container_no', headerName: 'container_no', editable: true , width: 150 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={() => handleDeleteContainer(params)} label="Delete" />,
      ]
    }
  ];

  const mutationBooking = updatePurchaseOrder([customerPO, containerNumber]);
    const handleRowClick = (params) => {
    params.row.container = containerNumber;
    setCustomerPO(params.row.customer_po);
    mutationBooking.mutate([customerPO, containerNumber]);
    
  };

  const handleRowEditCommit = (params, event) => {
    mutationBooking.mutate([params.row.customer_po, event.target.value])
  }

  return (

    <React.Fragment>
      <Title>{props.title}</Title>
      {props.bookingData && <DataGrid
        editMode="row"
        rows={props.bookingData}
        columns={columns}
        pageSizeOptions={[5]}
        getRowId={(bookingData) => bookingData.customer_po}
        onRowClick={handleRowClick}
        onRowEditStop={handleRowEditCommit}
      />}
    </React.Fragment>
  );
}
