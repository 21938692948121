import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Bookings from './bookings/Booking';
import Upload from './upload/Upload';

function AppRouter() {
    return (
      <Routes>
        <Route path="/" element={<Bookings></Bookings>} />
      </Routes>
    );
  }
  
  export default AppRouter;