import { ConstructionOutlined } from "@mui/icons-material";
import axios from "axios";

export default class ContainerAPI {
    static async getAllData({queryKey}) {
        //eslint-disable-next-line
            try {
                let res = await axios.get(`/Containers/GetAllContainers`);
                return res.data
            } catch (e) {
                console.error('Could not get purchase order data: ', e.message);
                return undefined;
            }
    }
}