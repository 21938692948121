import { ConstructionOutlined } from "@mui/icons-material";
import axios from "axios";

export default class ReportAPI {
    static async getReport({queryKey}) {
        //eslint-disable-next-line
            try {
                let res = await axios.get(`/reports/GenerateReport`);
                return res.data
            } catch (e) {
                console.error('Could not generate report: ', e.message);
                return undefined;
            }
    }
}