import { useQuery, useMutation } from "react-query";
import PurchaseOrderAPI from "./PurchaseOrder.api";

export function getPurchaseOrders(query){
    return useQuery(["fetchPurchaseOrders", query], PurchaseOrderAPI.getAllData, {refetchInterval:2000});
}

export function updatePurchaseOrder(query){
    return useMutation(["updatePurchaseOrder", query], PurchaseOrderAPI.updateData);
}

export function deletePurchaseOrderContainer(query){
    return useMutation(["deletePurchaseOrder", query], PurchaseOrderAPI.deleteContainerData);
}