import { ConstructionOutlined } from "@mui/icons-material";
import axios from "axios";

export default class PurchaseOrderAPI {
    static async getAllData({queryKey}) {
        //eslint-disable-next-line
            try {
                let res = await axios.get(`/PurchaseOrders/GetAllPOs`);
                return res.data
            } catch (e) {
                console.error('Could not get purchase order data: ', e.message);
                return undefined;
            }
    }

    static async updateData(queryKey) {
        //eslint-disable-next-line
        let customer_po_no = queryKey[0]
        let container_no = queryKey[1]
        if (customer_po_no)
        {
        try {
            const data = await axios.post(`/PurchaseOrders/${customer_po_no}/AssignContainerNumber/${container_no}`);
            console.log(data.data);
            return data.data;
        } catch (e) {
            console.error('Could not update purchase order data: ', e.message);
            return false;
        }
    }
    }

    static async deleteContainerData(queryKey) {
        //eslint-disable-next-line
        let customer_po_no = queryKey[0]
        let container_no = queryKey[1]
        if (customer_po_no)
        {
        try {
            const data = await axios.delete(`/PurchaseOrders/${customer_po_no}/AssignContainerNumber/`);
            console.log(data.data);
            return data.data;
        } catch (e) {
            console.error('Could not update purchase order data: ', e.message);
            return false;
        }
    }
    }
}